<template>
  <div v-if="questions.length" class="d-flex flex-column align-items-center">
    <footer class="font-small application-footer">
      <div class="text-center py-3">
        Maat © {{ new Date().getFullYear() }} | Todos os direitos reservados
      </div>
    </footer>
    <div
      class="min-h-65 d-flex flex-column align-items-center justify-content-center"
    >
      <b-card class="card-nps py-5 px-4">
        <template v-for="(question, index) in questions">
          <NPS
            :loading="loading"
            :total="questions.length"
            :current="current_step"
            :key="index"
            :question="question"
            v-if="current_step === index"
            @next="handleNextQuestion"
          />
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import { api } from "@/services/api";
import NPS from "./NPS.vue";

export default {
  components: {
    NPS,
  },
  data() {
    return {
      current_step: 0,
      total_steps: 0,
      loading: false,
      questions: [],
      description: "",
      thankYouText: "",
    };
  },
  methods: {
    handleNextQuestion() {
      this.current_step += 1;

      if (this.current_step === this.questions.length) {
        this.onSubmit();
      }
    },

    getQuestion() {
      api
        .get(`form/application/${this.formId}/`)
        .then(({ data: { questions, description, thankYouText } }) => {
          this.questions = questions;
          this.total_steps = questions.length;
          this.description = description;
          this.thankYouText = thankYouText;
        });
    },

    onSubmit() {
      this.loading = true;

      const questions = this.questions.map(({ id, answer }) => ({
        id,
        answer,
      }));

      api
        .patch(`form/application/answer/${this.formId}/`, {
          questions,
          form: this.formId,
        })
        .then(() => {
          setTimeout(() => {
            window.location.href = "https://maatgestao.com";
          }, 500);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.formId = this.$route.query.id;
    this.getQuestion();
  },
};
</script>
<style lang="scss" scoped>
.card-nps {
  max-width: 740px;
}
</style>
