<template>
  <div class="text-center">
    <strong class="d-block mb-3">
      {{ question.tagline }}
    </strong>
    <h3>{{ question.title }}</h3>
    <div class="d-flex justify-content-center align-items-start w-100 gap-2">
      <section v-for="choice in choices" :key="choice" class="text-center my-4">
        <b-button
          :disabled="loading"
          class="nps-button"
          :variant="getClassChoice(choice)"
          @click="loading ? null : handleSelectChoice(choice)"
        >
          <h4 class="m-0 text-white">{{ choice + 1 }}</h4>
        </b-button>
        <b-icon-caret-up-fill
          v-if="selectedChoice === choice + 1 && question.answer !== null"
        />
        <span v-else class="d-block py-2"> </span>
      </section>
    </div>
    <p class="mb-5">{{ question.description }}</p>
    <div class="d-flex justify-content-between align-items-center px-4">
      <span>{{ current + 1 }} de {{ total }}</span>
      <b-button
        v-if="!hide_next"
        :variant="
          question.answer === null || loading ? 'outline-light' : 'primary'
        "
        @click="loading ? null : $emit('next')"
        :disabled="question.answer === null || loading"
      >
        <span v-if="loading">
          <b-spinner small class="mr-2" />
          Enviando respostas
        </span>
        <span v-else>
          {{
            question.answer === null
              ? "Escolha uma nota"
              : `Avaliar com a nota ${question.answer}`
          }}
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formId: "",
      formKey: "",
      selectedChoice: 4,
      choices: Array.from(Array(10).keys()),
    };
  },

  props: {
    question: {
      type: Object,
    },
    hide_next: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    total: {
      type: Number,
    },
    current: {
      type: Number,
    },
  },

  methods: {
    getClassChoice(choice) {
      if (choice < 6) {
        return "danger";
      }
      if (choice >= 6 && choice <= 7) {
        return "warning";
      }
      return "success";
    },

    handleSelectChoice(choice) {
      this.selectedChoice = Number(choice + 1);
      this.question.answer = this.selectedChoice;
    },
  },

  mounted() {
    this.formId = this.$route.query.id;
    this.formKey = this.$route.query.key;
    this.selectedChoice = Number(this.$route.query.rating);
  },
};
</script>

<style lang="scss">
.nps-button {
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;

  &.danger {
    background: #ff0000;
  }
  &.neutral {
    background: #ff5b00;
  }
  &.success {
    background: #00ff55;
  }
}
</style>
